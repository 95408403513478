<template>
  <!-- begin::VehicleTypes edit -->
  <div>
    <div class="manager-title">
      <div>
        {{
          VehicleTypeID > 0
            ? $t("VEHICLE_TYPES.TITLE_EDIT")
            : $t("VEHICLE_TYPES.TITLE_ADD")
        }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <div class="manager-subtitle">
          {{ $t("VEHICLE_TYPES.SUBTITLE_DATA") }}
        </div>

        <div class="row form-group m-0">
          <label>
            {{ $t("VEHICLE_TYPES.NAME") }}
          </label>
        </div>
        <div class="row">
          <div
            v-for="(aLang, a) in LanguageList"
            :key="'Name_' + a"
            class="form-group col-lg-4"
          >
            <label>
              {{ aLang.name }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Name[aLang.lang]"
              required
              :rules="[rules.required]"
            />
          </div>
        </div>

        <div class="row form-group m-0">
          <label>
            {{ $t("VEHICLE_TYPES.DESCRIPTION") }}
            <span class="font-weight-normal">
              ({{ $t("GENERIC.OPTIONAL") }})
            </span>
          </label>
        </div>
        <div class="row">
          <div
            v-for="(aLang, a) in LanguageList"
            :key="'Desc_' + a"
            class="form-group col-lg-4"
          >
            <label>
              {{ aLang.name }}
            </label>
            <v-text-field
              class="form-control"
              v-model="Fields.Description[aLang.lang]"
            />
          </div>
        </div>

        <div class="manager-subtitle">
          {{ $t("VEHICLE_TYPES.SUBTITLE_DIMENSIONS") }}
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_TYPES.HEIGHT") }}
            </label>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>
                  {{ $t("VEHICLE_TYPES.MIN_MM") }}
                </label>
                <v-text-field
                  class="form-control"
                  v-model="Fields.HeightMin"
                  type="number"
                  min="0"
                  step="1"
                  required
                  :rules="[rules.required, rules.min0]"
                />
              </div>
              <div class="form-group col-lg-6">
                <label>
                  {{ $t("VEHICLE_TYPES.MAX_MM") }}
                </label>
                <v-text-field
                  class="form-control"
                  v-model="Fields.HeightMax"
                  type="number"
                  min="0"
                  step="1"
                  required
                  :rules="[rules.required, rules.heightMax]"
                />
              </div>
            </div>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_TYPES.LENGTH") }}
            </label>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>
                  {{ $t("VEHICLE_TYPES.MIN_MM") }}
                </label>
                <v-text-field
                  class="form-control"
                  v-model="Fields.LengthMin"
                  type="number"
                  min="0"
                  step="1"
                  required
                  :rules="[rules.required, rules.min0]"
                />
              </div>
              <div class="form-group col-lg-6">
                <label>
                  {{ $t("VEHICLE_TYPES.MAX_MM") }}
                </label>
                <v-text-field
                  class="form-control"
                  v-model="Fields.LengthMax"
                  type="number"
                  min="0"
                  step="1"
                  required
                  :rules="[rules.required, rules.lengthMax]"
                />
              </div>
            </div>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("VEHICLE_TYPES.WIDTH") }}
            </label>
            <div class="row">
              <div class="form-group col-lg-6">
                <label>
                  {{ $t("VEHICLE_TYPES.MIN_MM") }}
                </label>
                <v-text-field
                  class="form-control"
                  v-model="Fields.WidthMin"
                  type="number"
                  min="0"
                  step="1"
                  required
                  :rules="[rules.required, rules.min0]"
                />
              </div>
              <div class="form-group col-lg-6">
                <label>
                  {{ $t("VEHICLE_TYPES.MAX_MM") }}
                </label>
                <v-text-field
                  class="form-control"
                  v-model="Fields.WidthMax"
                  type="number"
                  min="1"
                  step="1"
                  required
                  :rules="[rules.required, rules.widthMax]"
                />
              </div>
            </div>
          </div>
        </div>
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push('/manager/vehicles/types')"
        >
          <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::VehicleTypes edit -->
</template>

<script>
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import VehicleTypeService from "@/core/services/api/v2/vehicletype.service";

export default {
  name: "VehicleTypesEdit",
  data() {
    return {
      VehicleTypeID: 0,
      LanguageList: i18nService.languages,
      Valid: true,
      Fields: {
        Name: {},
        Description: {},
        LengthMin: 0,
        LengthMax: 0,
        WidthMin: 0,
        WidthMax: 0,
        HeightMin: 0,
        HeightMax: 0
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        min0: value =>
          parseInt(value) >= 0 ||
          this.$i18n.t("VALIDATION.MIN_VALUE", { min: 0 }),
        heightMax: value =>
          parseInt(value) >= this.Fields.HeightMin ||
          this.$i18n.t("VALIDATION.GREATER_THAN_MIN"),
        lengthMax: value =>
          parseInt(value) >= this.Fields.LengthMin ||
          this.$i18n.t("VALIDATION.GREATER_THAN_MIN"),
        widthMax: value =>
          parseInt(value) >= this.Fields.WidthMin ||
          this.$i18n.t("VALIDATION.GREATER_THAN_MIN")
      }
    };
  },
  mounted() {
    // Edit (a certain "id") or create new?
    if (this.$route.params.id) {
      this.VehicleTypeID = parseInt(this.$route.params.id);
      this.loadVehicleModelData();
    } else {
      this.VehicleTypeID = -1;
    }
  },
  methods: {
    loadVehicleModelData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      VehicleTypeService.getProfile(this.VehicleTypeID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/vehicles/types");
        } else {
          this.VehicleTypeID = response.VehicleTypeID;

          for (var f1 in this.Fields) {
            this.Fields[f1] = response[f1];
          }

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Paranoid: integer sizes
        this.Fields.LengthMin = parseInt(this.Fields.LengthMin);
        this.Fields.LengthMax = parseInt(this.Fields.LengthMax);
        this.Fields.WidthMin = parseInt(this.Fields.WidthMin);
        this.Fields.WidthMax = parseInt(this.Fields.WidthMax);
        this.Fields.HeightMin = parseInt(this.Fields.HeightMin);
        this.Fields.HeightMax = parseInt(this.Fields.HeightMax);

        // Send request:
        if (this.VehicleTypeID > 0) {
          // Update VehicleType
          VehicleTypeService.updateProfile(
            this.VehicleTypeID,
            this.Fields
          ).then(statusCode => {
            if (statusCode >= 400) {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (statusCode === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        } else {
          // Create new VehicleType: we get back its ID
          VehicleTypeService.createProfile(this.Fields).then(response => {
            if (response.VehicleTypeID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.VehicleTypeID = response.VehicleTypeID;
              setTimeout(
                () =>
                  this.$router.push(
                    "/manager/vehicles/types/edit/" + response.VehicleTypeID
                  ),
                2000
              );
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button
            this.Valid = true;
          });
        }
      }
    }
  }
};
</script>
